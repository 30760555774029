import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import {
  GridItemPhotoStuff,
  getParagraphItem,
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import ItemCard from "../components/ResponsiveItemCard.jsx";
import Grid from "@material-ui/core/Grid";
import { parseWithFallback } from "../components/util/Functions";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($doctorID: ID!) {
    keystone {
      Doctor(where: { id: $doctorID }) {
        name
        specialization
        metaDesc
        coverImage {
          publicUrl
        }
        paragraphs(sortBy: order_ASC) {
          content
          order
          title
          image {
            publicUrl
          }
        }
        services(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        treatments(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        topics(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
      }
    }
  }
`;

const ElementsTemplate = ({ data }) => {
  const doc = data.keystone.Doctor;
  const paragraphs = data.keystone.Doctor.paragraphs;
  const services = data.keystone.Doctor.services;
  const treatments = data.keystone.Doctor.treatments;
  const topics = data.keystone.Doctor.topics;
  var itemsNumber = paragraphs.length + 1;
  return (
    <Fragment>
      <Helmet>
        <title>{doc.name} - Medico PG</title>
        <meta
          name="description"
          content={
            "Poliambulatorio Giano - Medico - " +
            doc.name +
            " - " +
            doc.specialization +
            " - " +
            doc.metaDesc
          }
        />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">I Nostri Medici</Text>
      </TitleBox>
      <Grid container>
        <GridItemPhotoStuff
          colorNumber={0}
          img={doc.coverImage ? doc.coverImage.publicUrl : undefined}
          centerHMode={true}
        >
          <h1
            sx={{ variant: "text.paragraphTitle", color: "black", margin: 0 }}
          >
            {doc.name}
          </h1>
          <h2 sx={{ variant: "text.paragraphTitle2", color: "black" }}>
            {doc.specialization}
          </h2>
        </GridItemPhotoStuff>
        {paragraphs.map((p, i) =>
          getParagraphItem(
            p.title,
            parseWithFallback(p.content),
            (1 + i) % 2,
            (1 + i) % 2 == 0,
            p.image,
            i
          )
        )}
        {services.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Servizi Correlati"
            collapsable={true}
          >
            {services.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {topics.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Ambiti Clinici Correlati"
            collapsable={true}
          >
            {topics.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {treatments.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Prestazioni Erogate"
            collapsable={true}
          >
            {treatments.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default ElementsTemplate;
